<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            Proud to support
            <span class="nowrap"> Newport World Resorts' </span> event with
            <span class="nowrap"> Chef Gordon Ramsay </span>
            in Manila!
          </h1>
          <div class="post-date">February 6, 2025</div>
        </section>

        <div class="main">
          <div class="image-container">
            <vLazyImage src="/img/articles/gordonramsay.png" />
          </div>

          <p>
            HelixPay is proud to have been part of Newport World Resorts’
            special event featuring Chef Gordon Ramsay’s first visit to Manila!
          </p>

          <p>To ensure a smooth guest experience, we provided:</p>

          <ul>
            <li>
              <div>Back-End Order Creation Flow</div>
              <div>For seamless pre-creation of group tickets.</div>
            </li>
            <li>
              <div>Ticket Claimable Links</div>
              <div>Making RSVP and personalization hassle-free.</div>
            </li>
            <li>
              <div>Dynamic Seat and Name Management</div>
              <div>Allowing real-time adjustments for guests.</div>
            </li>
          </ul>

          <p>
            At HelixPay, we’re honored to support world-class events and
            innovative partnerships that celebrate Philippines on the global
            stage. 🥂
          </p>

          <p>
            Learn about how we can also power your event by messaging us at
            <a href="mailto:hello@helixpay.ph">hello@helixpay.ph</a>. 📩
          </p>
        </div>
      </div>
    </main>

    <MainFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import vLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/gordonramsay.png';
const articlePath = '/articles/gordonramsay';

useHead(
  buildPageMeta({
    title: `Proud to support Newport World Resorts' event with Chef Gordon Ramsay in Manila!`,
    description: `HelixPay is proud to have been part of Newport World Resorts’ special event featuring Chef Gordon Ramsay’s first visit to Manila!`,
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/gordonramsay-bg.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #474848;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main p:first-child {
  margin-top: 0 !important;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 3rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.3rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.image-container {
  padding-bottom: 60.38%;
  background: #b5c9c4;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main strong {
  font-weight: 600;
}

.main h2 {
  font-size: 1.3rem;
}

.main p,
.main h2,
.main ul {
  margin: 2rem 1.5rem;
}

.main p,
.main li {
  font-size: 0.9rem;
  line-height: 1.5;
}

.post-date {
  margin-top: 16px;
  font-size: 12px;
  opacity: 0.7;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }

  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .post-date {
    font-size: 14px;
  }

  .main p,
  .main h2,
  .main ul {
    margin: 3rem auto;
    max-width: 512px;
  }

  .main p,
  .main li {
    font-size: 1rem;
  }

  .highlighted-text span {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .hero h1 {
    font-size: 1.75rem;
    font-weight: bold;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p,
  .main h2,
  .main ul {
    max-width: 610px;
  }

  .main p,
  .main li {
    font-size: 1.15rem;
  }
}

.main ul li + li {
  margin-top: 18px;
}

.main ul li div:first-child {
  font-weight: 500;
  margin-bottom: 6px;
}

.main h2 {
  margin-bottom: 0;
}

.main h2 + p {
  margin-top: 1rem;
}

.photo-credits {
  margin-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  opacity: 0.7;
}

strong {
  font-weight: 600;
}

a {
  color: #25a4e1;
}

.main ul {
  padding-left: 0;
  list-style: none;
}

.main ul li {
  position: relative;
  padding-left: 1.5em;
}

.main ul li:before {
  content: '✔';
  position: absolute;
  left: 0;
  width: 1em;
  height: 1em;
}

.pink-text {
  color: #ff5f8f !important;
}

.highlighted-text span {
  color: white;
  background: black;
  display: inline-block;
  padding-right: 4px;
  padding-left: 4px;
  line-height: 1.5;
}
</style>
